<template>
     <!-- 
        Список змін по співробітникам
        - дозволяє переглянути список змін з фільтрацією по співробітнику та періоду
        - відкрити картку співробітника
        - викликати створення співробітника
        - та інше :)
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @open - подія, яка спрацьовує при відкритті картки
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        :pages="objPages"
        :showpag="true"
        :columns="columns" 
        :rows="rows"
        @open="open"
        @getdata="getdata"
        @changeFilter="changeFilter" 
        @changelimit="changelimit"
    />

    <!-- 
       Виклик відкриття картки зміни
       - для відкриття застосовується перевірка значення modalDialog
       - @close - подія, яка спрацьовує при закритті картки
       - :obj - параметр-об'єкт з даними про зміну
    -->
    <viewbox 
        v-if="modalDialog == true"
        @close="modalDialog = false" 
        :obj="objCard" 
    />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from './view' // картка зміни
import { storeS } from "@/store"; // глобальне сховище даних
import { Users } from '@/API' // клас для взаємодії з API 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Users();

export default {
    components: {
        PageHeader,
        tablecustom,
        viewbox
    },
    data(){
    return {
            title: this.$t('shifts'), // Заголовок
            modalDialog: false, // прапорець відображення вікна зміни
            objCard: {}, // Об'єкт з даними картки для відображення в модальному вікні
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "shifts"
            },
            objParams:{ // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerId: '',
                pr1: '',
                pr2: ''
            },
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: "ID",
                    text: "shiftId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('Employee'),
                    text: "closedWorkerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("ShiftisOpen"),
                    text: "openTime",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("ShiftisClose"),
                    text: "closeTime",
                    align: "left",
                    status: true,
                },
            ],
            rows: [],// Дані для відображення у таблиці
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        getdata(page){
             /**
                Отримує дані про зміни з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.getAllShifts(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #505`);
                }
            })
        },
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про зміну за його ідентифікатором.
                * @param {Object} e - об'єкт картки зміни

                Викликає API для отримання інформації про зміну.
                Результат присвоюється властивості objCard, та встановлюється прапорці modalDialog в true для відображення модального вікна.
            */

            apiServe.getCardShift(e.shiftId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.modalDialog = true;
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка відкриття картки зміни'; 
                    var statusRequest = result.status;
                    var api = 'getCardShift';
                    var fileRequest = 'src/views/statistics/shifts/index.vue';
                    var params = e.shiftId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            }) 
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
    }
}
</script>