<template>
    <!-- модальное окно создания услуги -->
    <modal>
        <template v-slot:title>{{ $t('shift') }} </template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <!-- ID зміни -->
            <div class="mb-3">
                <h4 class="fs-15">ID</h4>
                <input type="number" class="form-control" v-model="form.shiftId" :disabled="this.editStatus == false">
            </div>
            <b-row>
                <!-- Дата та час коли зміна була відкрита -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('ShiftisOpen') }}</h4>
                        <input type="text" class="form-control" v-model="form.openTime" :disabled="this.editStatus == false">
                    </div>
                </b-col>
                <!-- Дата та час коли зміна була закрита -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('ShiftisClose') }}</h4>
                        <input type="text" class="form-control" v-model="form.closeTime" :disabled="this.editStatus == false">
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <!-- Коментар/причина запізнення -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('ReasonBeingLate') }}</h4>
                        <input type="text" class="form-control" v-model="form.lateComment" :disabled="this.editStatus == false">
                    </div>
                </b-col>
                <!-- Коментар/причина закриття зміни раніше -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('earlyClosingShift') }}</h4>
                        <input type="text" class="form-control" v-model="form.earlyCloseShiftComment" :disabled="this.editStatus == false">
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>

            <button 
                class="btn link-success fw-medium" 
                @click="$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} 
            </button>

        </template>
    </modal>

</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store.js'  // глобальне сховище даних

export default ({
    data(){
        return {
            form: {
                nomenclatureName: "",
                description: "",
                status: "",
                userPhone: "",
                price: ""
            },
            editStatus: false,
        }
    },
    props: ["obj"],
    components: { modal, Multiselect },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        closeModal() {
            /*
                Функція для закриття модального вікна.
            */
            this.$emit('close');
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
})
</script>

<style scoped>
.multiselect.is-disabled {
    background-color: var(--vz-input-disabled-bg)!important;
    opacity: 1;
}
</style>